<div class="wrapper">

    <app-navbar></app-navbar>

    <app-sidebar></app-sidebar>
    <div class="content-wrapper" style="min-height: 512px;">
        <div class="col-md-12 bg-gcc">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-4">
                            <!-- <img src="/assets/img/fevid.png" alt="FEVID" style="width: 55%; padding-left: 6%;"> -->
                        </div>
                        <div class="col-sm-4" style="text-align-last: center;">
                            <title class="ng-binding">Bascula </title>
                            <h3 class="ng-binding"> Bascula </h3>
                        </div>
                        <div class="col-sm-4">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a [routerLink]="['/dashboard']"
                                        routerLinkActive="router-link-active">Inicio</a></li>
                                <li class="breadcrumb-item active">Operaciones / Equipos / Detalles</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <section class="content">
                <div class="row p-3">
                    <div class="col-6 ">
                        <div class="small-box  bg-info">
                            <div class="row inner pl-4">
                                <table>
                                    <tr>
                                        <td class="h6 font-weight-bold"> ID sensor: </td>
                                        <td class="px-3 ng-binding"> {{tablaTablilla.tablilla?.board_id}} </td>
                                        <td class="h6 font-weight-bold"> Nombre del equipo:</td>
                                        <td class="px-3 ng-binding">{{ tablaTablilla.bascula?.Nombre}} </td>
                                    </tr>
                                    <tr>
                                        <td class="h6 font-weight-bold"> Última hora de reporte:</td>
                                        <td class="px-3 ng-binding">{{timestampToDate(tablaTablilla.tablilla?.creacionRegistro.seconds)}}</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="icon">
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <h4>Generar reporte de registros de la maquina: {{tablaTablilla.tablilla?.board_id  }}</h4>
                        <form [formGroup]="reportForm" (ngSubmit)="generateReport()">
                            <div class="row">
                                <div class="col-12 col-lg-6">
                                    <label for="initDate">Fecha de inicio</label>
                                    <input type="date" class="p-invalid input-group-append form-control"
                                        id="initDate" formControlName="initDate">
                                </div>
                                <div class="col-12 col-lg-6">
                                    <label for="finishDate">Fecha de termino</label>
                                    <input type="date" class="p-invalid input-group-append form-control"
                                        id="finishDate" formControlName="finishDate">
                                </div>
                            </div>
                            <div class="row pt-2">
                                <div class="col-12 text-center">
                                    <button class="btn btn-primary" type="submit">Generar reporte</button>
                                </div>
                            </div>
                        </form><br>
                    </div>
                      <div class="col-lg-12">
                    <div class="card card-teal">
                        <div class="card-header" id="accordionGraficaBom" data-toggle="collapse"
                            data-target="#graficaBom" aria-expanded="false" aria-controls="graficaBom">
                            <h5 class="card-title font-weight-bold">Reporte</h5>
                            <div class="card-tools">
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body show" id="graficaBom" data-parent="#accordionGraficaBom">
                            <div class="">
                                <div class="col-lg-12">
                                    <app-graph-point></app-graph-point>
                                    <!-- <app-cake-graph></app-cake-graph> -->
                                </div>
                            </div> <!-- /.row -->
                        </div> <!-- ./card-body -->
                    </div> <!-- /.card -->
                </div><!-- /.col -->
                </div>
              
            </section><!-- /.content -->
        </div>
    </div>
    <app-footer></app-footer>
</div>