import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators, } from '@angular/forms';
import { GetDataService } from '../../services/get-data.service';

// * Services
import { AuthService } from '../../services/auth.service';

declare const $: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  tablaSettings: any;
  idSettings: any;
  UnidadMetricaForm = new FormGroup({
    valuePerPulse: new FormControl('', [Validators.required]),
    materialDensity: new FormControl('', [Validators.required])
  });
  // DensidadMaterialForm = new FormGroup({
  //   densidadMaterial: new FormControl('', [Validators.required])
  // })

  constructor(private authService: AuthService, 
    private service: GetDataService,) { }

  ngOnInit(): void {
  }

  logout(): void {
    // * Preguntamos si desea cerrar sesión
    if (confirm('¿Está seguro que desea cerrar sesión?')) {
      // * Cerramos la sesión del usuario
      this.authService.logout();
    }
  }

  hideModal(){
    this.service.getSettings().snapshotChanges().subscribe(dataSettings => {
      dataSettings.map(settings => {
        let setting: any = settings.payload.doc.data();
      console.log("GET SETTINGS",setting)
        if(settings.payload.doc.id){
          this.idSettings = settings.payload.doc.id;
          document.getElementById('guardarValorPulsaciones')!.style.display = 'none';
          document.getElementById('ActualizarValorPulsaciones')!.style.display = 'block';
        }
        this.UnidadMetricaForm.setValue(setting);  
        // this.DensidadMaterialForm.setValue(setting.densidadMaterial);       
      });
    });
  }

  saveValorPulsaciones(){
    if (this.UnidadMetricaForm.valid) {
      this.service.addSettings(this.UnidadMetricaForm).then(() => {
        alert('Valores agregados con éxito.');
        $("#settingsModal").modal("hide");
      });
    }else{
      alert('El dato ingresado no es invalido, inténtalo de nuevo.');
    }
  }

  updateValorPulsaciones(){
    this.service.updateSettings(this.idSettings, this.UnidadMetricaForm.value).then(() => {
      alert('Valores actualizados con éxito.');
      $("#settingsModal").modal("hide")
    }).catch(() => alert('Error'))
  }
 
 
}
