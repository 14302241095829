<div id="fondoLogin">
    <section class="login-clean" id="sectionLogin">
      <form id="formulario" [formGroup]="loginForm" (ngSubmit)="login()">
        <h2 class="sr-only">Login Form</h2>
        <div class="illustration"
          style="background: url(&quot;assets/img/kinnil.png&quot;) center / contain no-repeat;height: 128px;"></div>
        <hr>
        <div class="illustration">
            <i class="fas fa-user-circle" style="color: white;"></i>
        </div>
        <div class="form-group"><label style="color: white;">Usuario</label><input class="form-control" type="email"
            formControlName="email" name="email" placeholder="nombre@ejemplo.com"
            style="border-radius: 20px;background: #343a40;border-style: solid;border-color: #0ddea1;color: white;">
        </div><label style="color: white;">Contraseña</label>
        <div class="form-group"><input class="form-control" type="password" name="password" placeholder="Contraseña"
            formControlName="password"
            style="border-radius: 20px;background: #343a40;border-style: solid;border-color: #0ddea1;color: white;">
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" type="submit"
            style="background: #343a40;color: #0ddea1;">Acceder&nbsp;<i class="fa fa-chevron-circle-right"></i>
          </button>
        </div>
        <button class="btn btn-primary btn-block" type="button" data-toggle="modal" data-target="#exampleModal"
          style="background: #343a40;color: #0ddea1;">¿Has olvidado tu contraseña?
        </button>
      </form>
    </section>
  </div>
  
  <!-- Modal Restore Password -->
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" style="background: #343a40;">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel" style="color: white;">Recuperar contraseña</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <div class="form-group">
              <label style="color: white;">Correo a recuperar</label>
              <input class="form-control" type="email" [(ngModel)]="emailRestore"
              name="email" placeholder="nombre@ejemplo.com"
              style="border-radius: 20px;background: #343a40;border-style: solid;border-color: #0ddea1;color: white;">
          </div>
            <small id="emailHelp" class="form-text text-muted">Si no recibe el correo de recuperación favor de esperar unos minutos y volver a hacer la solicitud.</small>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-block restorePasswordBtn" type="button" (click)="restorePassword()"
            style="background: #343a40;color: #0ddea1;">
            Recuperar
          </button>
        </div>
      </div>
    </div>
  </div>
  
