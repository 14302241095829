import { Component, OnInit } from '@angular/core';
import { RouterLink, Router } from '@angular/router';

import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { GetDataService } from '../../services/get-data.service';

// * Enviroment
import { environment } from 'src/environments/environment';

// * Utils
import { CheckPrivilegesService } from '../../utils/check-privileges.service';

import { AccordionModule } from 'primeng/accordion';     // * accordion and accordion tab
import { MenuItem } from 'primeng/api';                  // * api
import { Subject } from 'rxjs';

declare const $: any;

@Component({
  selector: 'app-machines',
  templateUrl: './machines.component.html',
  styleUrls: ['./machines.component.css']
})
export class MachinesComponent implements OnInit {

  // * Identificador de la página para los privilegios del usuario (Obligatorio en todas las páginas, es el mismo que esta en environment.ts)
  idMenuPage: number = 2.2;
  date: Date = new Date()
  hoy: String = this.date.getFullYear() + '-' + (this.date.getMonth() + 1) + '-' + this.date.getDay()


  // * Creamos la variable que tendra los datos para llenar la tabla
  tablaCamiones: any;
  tablaBasculas: any;
  showComponents = false;
  updateBascula = false

  temperaturaMax: number = 1;
  temperaturaMin: any = 1;
  submitted = false;
  camiones = ['Bomba', 'Trompo', 'Bascula óptica'];
  trucksForm = new FormGroup({
    IDSensor: new FormControl('', [Validators.required, Validators.nullValidator]),
    Nombre: new FormControl('', [Validators.required, Validators.nullValidator]),
    tipo: new FormControl('', [Validators.required]),
    anio: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+$')]),
    marca: new FormControl('', Validators.required),
    modelo: new FormControl('', Validators.required),
    placa: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^[A-Z0-9-]+$')]),
    gps: new FormControl('', [Validators.required, Validators.pattern('^[0-9-,.]+$')]),
    km: new FormControl('', Validators.required),
    vin: new FormControl(),
    uMantenimiento: new FormControl('', Validators.required),
    pMantenimiento: new FormControl('', Validators.required),
    lOperacion: new FormControl('', [Validators.required, Validators.min(1), Validators.max(60)]),
    lDetenido: new FormControl('', [Validators.required, Validators.min(1), Validators.max(60)]),
    pMin: new FormControl('', [Validators.required, Validators.min(1)]),
    pSPoint: new FormControl('', [Validators.required, Validators.min(1)]),
    pMax: new FormControl('', [Validators.required, Validators.min(1)]),
    tMin: new FormControl('', [Validators.required, Validators.min(1)]),
    tSPoint: new FormControl('', [Validators.required, Validators.min(1)]),
    tMax: new FormControl('', [Validators.required]),
    uid: new FormControl(),
    configVersion: new FormControl(),
  });

  presionMax: number = 1;
  camionesTabla: any = {
    messageEmpty: 'Ningún dato disponible en esta tabla',
    showSearch: true,
    showData: [5, 10, 15, 20, 25],
    headerRow: [{
      text: 'uid',
      show: false
    },{
      text: 'ID',
      show: true
    }, {
      text: 'Nombre',
      show: true
    }, {
      text: 'Tipo',
      show: true
    }, {
      text: 'Placas',
      show: true
    }, {
      text: 'Año',
      show: true
    }, {
      text: 'Marca',
      show: true
    }, {
      text: 'Modelo',
      show: true
    }],
    buttons: {
      detail: {
        show: true,
        text: 'Detail',
      },
      edit: {
        show: true,
        text: 'Edit',
      },
      delete: {
        show: true,
        text: 'Delete',
      }
    }
  };
  dataRows:Array<any>=[];
  basculaTabla: any = {
    messageEmpty: 'Ningún dato disponible en esta tabla',
    showSearch: true,
    showData: [5, 10, 15, 20, 25],
    headerRow: [{
      text: 'uid',
      show: false
    },{
      text: 'ID',
      show: true
    }, {
      text: 'Nombre',
      show: true
    }, {
      text: 'Tipo',
      show: true
    }, {
      text: 'Marca',
      show: true
    }, {
      text: 'Modelo',
      show: true
    }],
    buttons: {
      detail: {
        show: false,
        text: 'Detail',
      },
      edit: {
        show: true,
        text: 'Edit',
      },
      delete: {
        show: true,
        text: 'Delete',
      }
    }
  };
  dataRowsBasculas:Array<any>=[];



  constructor(private formBuilder: FormBuilder,
    private service: GetDataService,
    private checkPrivileges: CheckPrivilegesService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.service.getTrucksData().snapshotChanges().subscribe(dataT => {
      this.tablaCamiones = []
      this.dataRows=[]
      dataT.map(truck => {
        let camion: any = truck.payload.doc.data();
        camion.uid = truck.payload.doc.id;
        this.tablaCamiones.push(camion);
        this.dataRows.push(([camion.uid,camion.IDSensor, camion.Nombre, camion.tipo, camion.placa, camion.anio, camion.marca, camion.modelo]));
      });
      // * Mostramos el mapa y tabla de sensores
      this.showComponents = true;
    });

    this.service.getBasculaData().snapshotChanges().subscribe(dataT => {
      this.tablaBasculas = []
      this.dataRowsBasculas=[]
      dataT.map(bas => {
        let bascula: any = bas.payload.doc.data();
        bascula.uid = bas.payload.doc.id;
        this.tablaBasculas.push(bascula);
        this.dataRowsBasculas.push(([bascula.uid,bascula.IDSensor, bascula.Nombre, bascula.tipo, bascula.marca, bascula.modelo]));
      });
      // * Mostramos el mapa y tabla de sensores
      this.showComponents = true;
    });
  }

  // * Crear camión nuevo
  onTrucks() {
    let confirm = false;
    var tipo = this.trucksForm.value.tipo;
    if (this.trucksForm.valid) {
      this.tablaCamiones.forEach((x: any) => {
        if (x.IDSensor == this.trucksForm.value.IDSensor) {
          alert('ID (tablilla) ya utilizado')
          confirm = true
        }
      });
      if (confirm == false) {
        this.trucksForm.value.configVersion = `${this.trucksForm.value.IDSensor}-0A`
        if(tipo === 'Bascula óptica'){
          this.service.addBascula(this.trucksForm).then(() => {
            alert('Bascula registrada con éxito');
            $("#modalNewMachine").modal("show");
          });
        }else{
          this.service.setTruckData(this.trucksForm).then(() => {
            alert('Camión registrado con éxito');
            $("#modalNewMachine").modal("show");
          });
        }
      
      }
    } else {
      alert('Datos inválidos, favor de revisar la información')
    }
  }

  // * Resetea modal para crear camión nuevo 
  onModal() {
    this.trucksForm.reset();
    return true
  }

  // * Actualizacion de camión en la base de datos
  onEditTruck(uid: any,) {
    console.log("UPDATE BASCULA", this.trucksForm);
    if (this.trucksForm.valid) {
      let configVersion = this.trucksForm.value.configVersion.split('-');
      let numero = +configVersion[1].match(/(\d+)/g)
      let letra: any = new String(configVersion[1].match(/([a-zA-Z ]+)/g)).codePointAt(0)
      if ((letra + 1) === 91) {
        letra = 65;
        numero += 1;
      } else { letra += 1; }
      this.trucksForm.value.configVersion = '' + configVersion[0] + '-' + numero + String.fromCodePoint(letra)
      if (this.trucksForm.value.tMax < this.trucksForm.value.tMin) {
        alert('Temperatura minima no puedes er mayo a maxima');
      } else if (this.trucksForm.value.pMax < this.trucksForm.value.pMin) {
        alert('Presión minima no puedes er mayo a maxima');
      } else {
        if(this.updateBascula){
          this.service.updateBasculaData(uid, this.trucksForm.value).then(() => {
            alert('Bascula actualizada con éxito');
            this.showInputs();
            $("#modalNewMachine").modal("hide")
          }).catch(() => alert('Error'))
        }else{
          this.service.updateTruckData(uid, this.trucksForm.value).then(() => {
            alert('Camión actualizado con éxito');
            $("#modalNewMachine").modal("hide")
          }).catch(() => alert('Error'))
        }
       
      }
    }
    else {
      alert('Datos inválidos, favor de revisar la información')
    }
  }
 
  // * Obtenemos los privilegios del usuario
  verifiPrivileges(privileges: any): boolean {
    return this.checkPrivileges.checkPrivileges(privileges, this.idMenuPage);
  }

  hideInputs(){
    var tipo = this.trucksForm.value.tipo;
    switch (tipo) {
      case 'Bascula óptica':
        document.getElementById('placa')!.style.display = 'none';
        document.getElementById('odometro')!.style.display = 'none';
        document.getElementById('kmUltimoSer')!.style.display = 'none';
        document.getElementById('ano')!.style.display = 'none';
        document.getElementById('lecturaOperacion')!.style.display = 'none';
        document.getElementById('lecturaDetenido')!.style.display = 'none';
        document.getElementById('presion')!.style.display = 'none';
        document.getElementById('temperatura')!.style.display = 'none';

        this.trucksForm.get('anio')?.setErrors(null);
        this.trucksForm.get('placa')?.setErrors(null);
        this.trucksForm.get('gps')?.setErrors(null);
        this.trucksForm.get('km')?.setErrors(null);
        this.trucksForm.get('lOperacion')?.setErrors(null);
        this.trucksForm.get('lDetenido')?.setErrors(null);
        this.trucksForm.get('pMin')?.setErrors(null);
        this.trucksForm.get('pSPoint')?.setErrors(null);
        this.trucksForm.get('pMax')?.setErrors(null);
        this.trucksForm.get('tMin')?.setErrors(null);
        this.trucksForm.get('tSPoint')?.setErrors(null);
        this.trucksForm.get('tMax')?.setErrors(null);

        console.log("formulario sin validación", this.trucksForm);
        break;
      default:
        document.getElementById('placa')!.style.display = 'block';
        document.getElementById('odometro')!.style.display = 'block';
        document.getElementById('kmUltimoSer')!.style.display = 'block';
        document.getElementById('ano')!.style.display = 'block';
        document.getElementById('lecturaOperacion')!.style.display = 'block';
        document.getElementById('lecturaDetenido')!.style.display = 'block';
        document.getElementById('presion')!.style.display = 'block';
        document.getElementById('temperatura')!.style.display = 'block';

        this.trucksForm.controls['anio']?.addValidators([Validators.required, Validators.pattern('^[0-9]+$')]);
        this.trucksForm.controls['placa']?.addValidators([Validators.required, Validators.maxLength(10), Validators.pattern('^[A-Z0-9-]+$')]);
        this.trucksForm.controls['gps']?.addValidators([Validators.required, Validators.pattern('^[0-9-,.]+$')]);
        this.trucksForm.controls['km']?.addValidators([Validators.required]);
        this.trucksForm.controls['lOperacion']?.addValidators([Validators.required, Validators.min(1), Validators.max(60)]);
        this.trucksForm.controls['lDetenido']?.addValidators([Validators.required, Validators.min(1), Validators.max(60)]);
        this.trucksForm.controls['pMin']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['pSPoint']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['pMax']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['tMin']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['tSPoint']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['tMax']?.addValidators([Validators.required]);

        this.trucksForm.controls['anio'].updateValueAndValidity();
        this.trucksForm.controls['placa'].updateValueAndValidity();
        this.trucksForm.controls['gps'].updateValueAndValidity();
        this.trucksForm.controls['km'].updateValueAndValidity();
        this.trucksForm.controls['lOperacion'].updateValueAndValidity();
        this.trucksForm.controls['lDetenido'].updateValueAndValidity();
        this.trucksForm.controls['pMin'].updateValueAndValidity();
        this.trucksForm.controls['pSPoint'].updateValueAndValidity();
        this.trucksForm.controls['pMax'].updateValueAndValidity();
        this.trucksForm.controls['tMin'].updateValueAndValidity();
        this.trucksForm.controls['tSPoint'].updateValueAndValidity();
        this.trucksForm.controls['tMax'].updateValueAndValidity();
        

        break;
    }
  }
  showInputs(){
    console.log("SHOW INPUTS")
    document.getElementById('placa')!.style.display = 'block';
        document.getElementById('odometro')!.style.display = 'block';
        document.getElementById('kmUltimoSer')!.style.display = 'block';
        document.getElementById('ano')!.style.display = 'block';
        document.getElementById('lecturaOperacion')!.style.display = 'block';
        document.getElementById('lecturaDetenido')!.style.display = 'block';
        document.getElementById('presion')!.style.display = 'block';
        document.getElementById('temperatura')!.style.display = 'block';

        this.trucksForm.controls['anio']?.addValidators([Validators.required, Validators.pattern('^[0-9]+$')]);
        this.trucksForm.controls['placa']?.addValidators([Validators.required, Validators.maxLength(10), Validators.pattern('^[A-Z0-9-]+$')]);
        this.trucksForm.controls['gps']?.addValidators([Validators.required, Validators.pattern('^[0-9-,.]+$')]);
        this.trucksForm.controls['km']?.addValidators([Validators.required]);
        this.trucksForm.controls['lOperacion']?.addValidators([Validators.required, Validators.min(1), Validators.max(60)]);
        this.trucksForm.controls['lDetenido']?.addValidators([Validators.required, Validators.min(1), Validators.max(60)]);
        this.trucksForm.controls['pMin']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['pSPoint']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['pMax']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['tMin']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['tSPoint']?.addValidators([Validators.required, Validators.min(1)]);
        this.trucksForm.controls['tMax']?.addValidators([Validators.required]);

        this.trucksForm.controls['anio'].updateValueAndValidity();
        this.trucksForm.controls['placa'].updateValueAndValidity();
        this.trucksForm.controls['gps'].updateValueAndValidity();
        this.trucksForm.controls['km'].updateValueAndValidity();
        this.trucksForm.controls['lOperacion'].updateValueAndValidity();
        this.trucksForm.controls['lDetenido'].updateValueAndValidity();
        this.trucksForm.controls['pMin'].updateValueAndValidity();
        this.trucksForm.controls['pSPoint'].updateValueAndValidity();
        this.trucksForm.controls['pMax'].updateValueAndValidity();
        this.trucksForm.controls['tMin'].updateValueAndValidity();
        this.trucksForm.controls['tSPoint'].updateValueAndValidity();
        this.trucksForm.controls['tMax'].updateValueAndValidity();
        
  }
  // * Abrir Componente detalle camion
  detalle(data: any, tipo: number) {
    switch (tipo) {
      case 1:
        this.tablaCamiones.forEach((camion: any) => {
          if (camion.uid === data.uid) {
            this.router.navigate(['/machine-detail/' + camion.uid]);
          }
        });
        break;
    
      case 2:
        $("#desarrolloModal").modal("show")
        break;
    }
   
   
  }
  // * Abrir modal de editar
  edit(data: any, tipo: number) {
    switch (tipo) {
      case 1:
        this.tablaCamiones.forEach((camion: any) => {
          if (camion.uid === data.uid) {
            this.trucksForm.setValue(camion);
            $("#modalNewMachine").modal("show")
          }
        });
        break;
    
        case 2:
          this.updateBascula = true;
          this.tablaBasculas.forEach((bascula: any) => {
            if (bascula.uid === data.uid) {
              console.log("EDITA BASCULA", bascula.uid);
            console.log("EDITA DATA", data.uid);
              this.trucksForm.setValue(bascula);
              this.hideInputs();
              $("#modalNewMachine").modal("show")
            }
          });
          console.log("UPDATE BASCULA", this.updateBascula);
        break;
    }
    
  }

   // * Eliminar camión
  delete(data: any, tipo: number) {
    switch (tipo) {
      case 1:
        this.tablaCamiones.forEach((camion: any) => {
          if (camion.uid === data.uid) {
            if (confirm('¿Seguro de que desea eliminar este equipo?')) {
              this.service.deleteTruckData(camion.uid).then(() => {
                alert('Camión eliminado con éxito')
              });
            }
          }
        });
        break;
    
      case 2:
        this.tablaBasculas.forEach((bascula: any) => {
          if (bascula.uid === data.uid) {
            if (confirm('¿Seguro de que desea eliminar esta báscula?')) {
              this.service.deleteTruckData(bascula.uid).then(() => {
                alert('Bascula eliminada con éxito')
              });
            }
          }
        });
        break;
    }
   
  }

}
