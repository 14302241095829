import { Injectable } from '@angular/core';

// * Firebase
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class GetDataService {

  constructor(private afs: AngularFirestore) { }

  getData(collection: string, id: string) {
    return this.afs.collection(collection, ref => ref.where('id', '==', id).orderBy('creacionRegistro', 'desc').limit(1)).snapshotChanges();
  }

  getDataBascula(collection: string, id: string) {
     return this.afs.collection(collection, ref => ref.where('board_id', '==', id).orderBy('creacionRegistro', 'desc').limit(1)).snapshotChanges();
  }
  
  getDataByDaysBasculaChart(collection: string, id: string, subtract: number){
    // Hoy
    const originalTime = new Date().getTime();
    // Lo pasado
    const newDate = originalTime - ((1440 * 60000) * subtract);
    return this.afs.collection(collection, ref => ref
      .where('board_id', '==', id)
      .where('creacionRegistro', '>=', new Date(newDate))
      .where('creacionRegistro', '<=', new Date(originalTime))
      .orderBy('creacionRegistro', 'desc')).snapshotChanges();
  }

  getDataAllBasculaChart(collection: string, id: string){
    return this.afs.collection(collection, ref => ref
      .where('board_id', '==', id)
      .orderBy('creacionRegistro', 'desc')).snapshotChanges();
  }
    
  getDataChart(collection: string, id: string) {
    return this.afs.collection(collection, ref => ref
      .where('id', '==', id)
      .orderBy('creacionRegistro', 'desc').limit(50)).snapshotChanges();
  }

  async getDataList(collection: string) {
    return await this.afs.collection(collection).ref.get();
  }

  getTruckData(id: string) {
    return this.afs.collection('camiones', ref => ref.where('IDSensor', '==', id)).snapshotChanges();
  }

  getTrucksData() {
    return this.afs.collection('camiones')
  }

  getAllTrucksData() {
    return this.afs.collection('camiones').snapshotChanges();
  }

  async setTruckData(truck: any) {
    return this.afs.collection('camiones').add(truck.value)
  }

  async updateTruckData(doc: any, data: any) {
    return this.afs.collection('camiones').doc(doc).set(data);
  }
  
  async deleteTruckData(doc: any) {
    return this.afs.collection('camiones').doc(doc).delete()
  }

  async generateReport(initDate: string, finishDate: string) {
    return this.afs.collection('quectel', ref => ref.where("creacionRegistro", ">=", new Date(`${initDate} 00:00:00`)).where("creacionRegistro", "<=", new Date(`${finishDate} 23:59:59`))).get();
  }

  async generateReportBascula(initDate: string, finishDate: string){
    return this.afs.collection('op_iotCore', ref => ref.where("creacionRegistro", ">=", new Date(`${initDate} 00:00:00`)).where("creacionRegistro", "<=", new Date(`${finishDate} 23:59:59`))).get();
  }

  async addBascula(bascula: any){
    return this.afs.collection('basculas').add(bascula.value)
  }

  async updateBasculaData(doc: any, data: any) {
    return this.afs.collection('basculas').doc(doc).set(data);
  }

  getAllBasculasData() {
    return this.afs.collection('basculas').snapshotChanges();
  }

  getBasculaByIdData(id: string) {
    return this.afs.collection('basculas', ref => ref.where('IDSensor', '==', id)).snapshotChanges();
  }

  getBasculaData() {
    return this.afs.collection('basculas');
  }

  getDataIOTCore(){
    return this.afs.collection('op_iotCore').snapshotChanges();
  }

  async addSettings(unidad: any){
    return this.afs.collection('settings').add(unidad.value)
  }

  getSettings() {
    return this.afs.collection('settings')
  }

  async updateSettings(doc: any, data: any) {
    return this.afs.collection('settings').doc(doc).set(data);
  }
}
