<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-dark">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
        </li>
    </ul>

    <ul class="navbar-nav">
        <li class="nav-item">
            <img src="assets/img/kinnil.png" alt="logo Kinnil" style="height: 55px;">
        </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
        <li class="nav-item">
            <a class="nav-link" (click)="hideModal()" role="button" data-toggle="modal" data-target="#settingsModal">
                <i class="fas fa-cogs"></i>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="logout()" role="button">
                <i class="fas fa-sign-out-alt"></i>
            </a>
        </li>
    </ul>
</nav>

<div class="modal fade" id="settingsModal" data-backdrop="static" data-keyboard="false" tabindex="-1"
    aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="staticBackdropLabel">Configuración</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form class="form-group" [formGroup]="UnidadMetricaForm" (ngSubmit)="saveValorPulsaciones()">
                <div class="modal-body">
                    <div class="" id="myTabContent">
                        <!-- Contenido valor de pulsaciones. -->
                        <div class=" tab-pane fade show" id="unidadMetrica" role="tabpanel"
                            aria-labelledby="unidadMetrica-tab">
                            <div class="row">
                                <div class="col-12 pt-4 pb-3">

                                    <div class="form-group mx-sm-3 mb-2">
                                        <label for="valuePerPulse">Valor por pulsaciones</label>
                                        <input type="number" class="form-control" id="valuePerPulse"
                                            placeholder="Valor por pulsación" formControlName="valuePerPulse">
                                    </div>
                                    <div class="form-group mx-sm-3 mb-2">
                                        <label for="materialDensity">Densidad del material</label>
                                        <input type="number" class="form-control" id="materialDensity"
                                            placeholder="Densidad del material" formControlName="materialDensity">
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="submit" class="btn btn-success mb-2 " id="guardarValorPulsaciones">Guardar</button>
                    <button type="button" class="btn btn-success mb-2 ml-3" id="ActualizarValorPulsaciones"
                        style="display: none;" (click)="updateValorPulsaciones()">Actualizar</button>
                </div>
            </form>
        </div>
    </div>
</div>