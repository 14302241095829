import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

// import { Chart } from 'chart.js';
import { Chart, ChartConfiguration, ChartItem, Colors, registerables } from 'node_modules/chart.js'

// * Services
import { DatePipe } from '@angular/common';
import { GetDataService } from '../../services/get-data.service';

@Component({
  selector: 'app-graph-point',
  templateUrl: './graph-point.component.html',
  styleUrls: ['./graph-point.component.css']
})
export class GraphPointComponent implements OnInit {
  public chart: any = null;
  arrayData: any[] = [];
  dataSet: any[] = [];
  labelSet: any[] = [];
  dataUnique: any[] = [];
  chartItem: ChartItem = '';

  labelGraph: string = '1 día';

  constructor(private service: GetDataService,
    private routeparam: ActivatedRoute,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    var temp: any
    this.routeparam.snapshot.paramMap.get('type')
    const idBoard = this.routeparam.snapshot.paramMap.get('uid');

    this.service.getDataByDaysBasculaChart('op_iotCore', idBoard?.toString() || '', 1).subscribe(data => {
      console.log("DATA", data.length);
      
      data.map(item => {
        temp = item.payload.doc.data();

        this.arrayData.push(temp);
      });
      this.createChart(this.arrayData);
    });
  }

  createChart(dataGraph: any) {
    let myChart: any;

    this.dataSet = [];
    this.labelSet = [];
    this.arrayData = []

    // Se cicla la informacion para obtener las etiquetas del eje x y poder acceder a los datos para calcular las libras
    for (let i = 0; i < dataGraph.length; i++) {
      const element = dataGraph[i];
      var libras = ((element.valuePerPulse * element.pulses) * element.materialDensity) * 2.20462;
      var milisegundos = element.creacionRegistro.seconds * 1000;
      var date = new Date(milisegundos);
      var dateFormat = this.datePipe.transform(date, 'dd/MM/YY HH:mm:ss');
      this.dataSet.push(libras);
      this.labelSet.push(dateFormat);
    }

    Chart.register(...registerables);
    Chart.register(Colors);

    const data = {
      labels: this.labelSet,
      datasets: [{
        label: 'Libras',
        borderColor: 'rgba(54, 162, 235, 1)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
        fill: true,
        data: this.dataSet,
      }]
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          max: 100,
          display: true
        }
      }
    }

    const config: ChartConfiguration = {
      type: 'line',
      data: data,
      plugins: []
    }

    // Se busca la existencia de una grafica para destruirla
    var existingChart = Chart.getChart('chartPoint');
    if (existingChart) {
      existingChart.destroy();
    }

    // Se crea la grafica 
    this.chartItem = document.getElementById('chartPoint') as ChartItem
    myChart = new Chart(this.chartItem, config);

  }

  filterDay(days: number) {
    var temp: any
    this.dataSet = [];
    this.labelSet = [];
    this.routeparam.snapshot.paramMap.get('type')
    const idBoard = this.routeparam.snapshot.paramMap.get('uid');
    this.service.getDataByDaysBasculaChart('op_iotCore', idBoard?.toString() || '', days).subscribe(data => {
      data.map(item => {
        temp = item.payload.doc.data();
        this.arrayData.push(temp);
      });
      this.createChart(this.arrayData);
    });
  }

  filterHistorical() {
    var temp: any
    this.dataSet = [];
    this.labelSet = [];
    this.routeparam.snapshot.paramMap.get('type')
    const idBoard = this.routeparam.snapshot.paramMap.get('uid');
    this.service.getDataAllBasculaChart('op_iotCore', idBoard?.toString() || '').subscribe(data => {
      data.map(item => {
        temp = item.payload.doc.data();
        this.arrayData.push(temp);
      });
      this.createChart(this.arrayData);
    });
  }
}
