<div class="wrapper">

    <app-navbar></app-navbar>

    <app-sidebar></app-sidebar>

    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">Privilegios</h1>
                    </div>
                    <!-- /.col -->
                    <!--div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Dashboard v2</li>
                    </ol>
                </div-->
                    <!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <div class="container-fluid">
            <div class="col-md-12">
                <div class="card card-teal">
                    <div class="card-header" id="accordionPrivilegios" data-toggle="collapse" data-target="#privilegios"
                        aria-expanded="false" aria-controls="privilegios">
                        <h5 class="card-title">Usuarios</h5>
                    </div>
                    <!-- /.card-header -->
                    <div class="card-body show" id="privilegios" data-parent="#accordionPrivilegios">
                        <div class="row">
                            <div class="col-md-2" *ngIf="verifiPrivileges([3])">
                                <div class="input-group mb-3">
                                    <button type="button" class="btn btn-block btn-info" data-toggle="modal"
                                        data-target="#modalCrearUsuario">
                                        <i class="fas fa-plus"></i> Agregar Nuevo Usuario</button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <table id="tablaWells" datatable
                                    class="table table-striped table-bordered dt-responsive " style="width:100%">
                                    <thead>
                                        <tr>
                                            <th>N° de Empleado</th>
                                            <th>Nombre de usuario</th>
                                            <th>Correo</th>
                                            <th>Teléfono</th>
                                            <th>Rol</th>
                                            <th>Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let user of users; let i = index">
                                            <td style="width: 30px;">{{user.data.noEmployee}}</td>
                                            <td>{{user.data.userName}}</td>
                                            <td>{{user.data.email}}</td>
                                            <td>{{user.data.telephone}}</td>
                                            <td>{{user.data.role}}</td>
                                            <td *ngIf="verifiPrivileges([1,2,3])">
                                                <div style="text-align: center;" *ngIf="verifiPrivileges([1])">
                                                    <button type="button" class="btn-primary" style="padding: 5px;"
                                                        routerLink="/privileges-detail/{{user.data.userName}}/{{user.uid}}">
                                                        <i class="fas fa-exclamation"></i> Ver Permisos</button>
                                                </div>
                                                <div style="text-align: center;" *ngIf="verifiPrivileges([2])">
                                                    <button type="button" class="btn-info" data-toggle="modal"
                                                        data-target="#modalEditarUsuario"
                                                        (click)="initModalEditUser(user)" ng
                                                        style="padding: 5px; margin-right: 10px;">
                                                        <i class="fas fa-pencil"></i> Editar</button>
                                                    <button type="button" class="btn-primary" style="padding: 5px;"
                                                        routerLink="/privileges-detail/{{user.data.userName}}/{{user.uid}}">
                                                        <i class="fas fa-exclamation"></i> Ver Permisos</button>
                                                </div>
                                                <div style="text-align: center;" *ngIf="verifiPrivileges([3])">
                                                    <button type="button" class="btn-info" data-toggle="modal"
                                                        data-target="#modalEditarUsuario"
                                                        (click)="initModalEditUser(user)" ng
                                                        style="padding: 5px; margin-right: 10px;">
                                                        <i class="fas fa-pencil"></i> Editar</button>
                                                    <button type="button" class="btn-danger" (click)="deleteUser(user)"
                                                        style="padding: 5px; margin-right: 10px;">
                                                        <i class="fas fa-trash"></i> Eliminar</button>
                                                    <button type="button" class="btn-primary" style="padding: 5px;"
                                                        routerLink="/privileges-detail/{{user.data.userName}}/{{user.uid}}">
                                                        <i class="fas fa-exclamation"></i> Ver Permisos</button>
                                                </div>
                                            </td>
                                            <td *ngIf="!verifiPrivileges([1,2,3])">
                                                <p>Sin privilegios</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div> <!-- /.row -->
                    </div> <!-- ./card-body -->
                </div>
            </div>
        </div>
    </div>

    <!-- Inicio Modal para Crear Nuevo Usuario -->
    <div class="modal fade" id="modalCrearUsuario" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Crear Usuario</h5>
                    <button type="button" id="modalCrearUsuarioCerrar" class="close" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form [formGroup]="newUserForm" (ngSubmit)="createUser()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="nombreUsuario">N° de Empleado</label>
                                    <input type="number" class="form-control" id="numEmployee"
                                        formControlName="noEmployee" maxlength="7"
                                        oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        placeholder="Máximo 7 caracteres">
                                </div>
                                <div class="form-group">
                                    <label for="nombreUsuario">Nombre de Usuario</label>
                                    <input type="text" class="form-control" id="nombreUsuario"
                                        formControlName="userName" onkeydown="return /[a-z, ]/i.test(event.key)"
                                        maxlength="17"
                                        oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        placeholder="Máximo 17 caracteres">
                                </div>
                                <div class="form-group">
                                    <label for="password">Contraseña</label>
                                    <input type="password" class="form-control" id="password" formControlName="password"
                                        placeholder="Mínimo 6 caracteres">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="correoUsuario">Correo Electrónico</label>
                                    <input type="text" class="form-control" id="correoUsuario" formControlName="email"
                                        placeholder="ejemplo@ejemplo.com">
                                </div>
                                <div class="form-group">
                                    <label for="confirmPassword">Confirmar Contraseña</label>
                                    <input type="password" class="form-control" id="confirmPassword"
                                        formControlName="confirmPassword" placeholder="Mínimo 6 caracteres">
                                </div>
                                <div class="form-group">
                                    <label for="telephone">Teléfono</label>
                                    <input type="number" class="form-control" id="telephoneNewUser"
                                        formControlName="telephone" minlength="10" maxlength="10"
                                        oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        placeholder="Mínimo 10 caracteres">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="rol">Roles</label>
                                    <select class="input-group mb-3" formControlName="role">
                                        <option value="Usuario">Usuario</option>
                                        <option value="Super Usuario">Super Usuario</option>
                                    </select>
                                </div>
                            </div>
                            <!--div class="col-12">
                    <div class="form-group">
                        <label for="itsClientCreate">¿Este usuario es un cliente?</label>
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="itsClientCreate">
                        </div>
                    </div>
                    </div-->
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                        <button type="submit" class="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!--  Fin Modal para Crear Nuevo Usuario -->

    <!-- Inicio Modal para Editar Nuevo Usuario -->
    <div class="modal fade" id="modalEditarUsuario" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Editar Usuario</h5>
                    <button type="button" class="close" id="modalEditarUsuarioCerrar" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <form [formGroup]="editUserForm" (ngSubmit)="editUser()">
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="userName">Nombre de usuario</label>
                                    <input type="text" class="form-control" id="userName" formControlName="userName"
                                        onkeydown="return /[a-z, ]/i.test(event.key)" maxlength="17"
                                        oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        [(ngModel)]="userEdit.username" placeholder="Máximo 17 caracteres">
                                </div>
                                <div class="form-group">
                                    <label for="telephone">Teléfono</label>
                                    <input type="number" class="form-control" id="telephoneEditUser"
                                        formControlName="telephone" minlength="10" maxlength="10"
                                        oninput="if(this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        [(ngModel)]="userEdit.telephone" placeholder="Mínimo 10 caracteres">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="email">Correo</label>
                                    <input type="text" class="form-control" id="email" formControlName="email"
                                        [(ngModel)]="userEdit.email" placeholder="ejemplo@ejemplo.com">
                                </div>
                                <!--div class="form-group">
                    <label for="itsClient">¿Este usuario es un cliente?</label>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="itsClient">
                    </div>
                    </div-->
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Cancelar</button>
                        <button type="submit" class="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!--  Fin Modal para Editar Nuevo Usuario -->

    <app-footer></app-footer>

</div>