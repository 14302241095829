<div class="col-12 col-lg-12">
    {{ labelGraph }}
    <br>
    <div id="filterGraficaSand" class="btn-group" role="group"  aria-label="Basic example">
        <button type="button" class="btn btn-secondary dimensions btn-grafica" id="graphOneDay"  (click)='filterDay(1); labelGraph = "1 día"'>
            1D
        </button>
        <button type="button" class="btn btn-secondary dimensions btn-grafica" id="graphFiveDay" (click)='filterDay(5); labelGraph = "5 días"'>
            5D
        </button>
        <button type="button" class="btn btn-secondary dimensions btn-grafica" id="graphOneMonth" (click)='filterDay(30); labelGraph = "1 mes"'>
            1M
        </button>
        <button type="button" class="btn btn-secondary dimensions btn-grafica" id="graphSixsMonths" (click)='filterDay(180); labelGraph = "6 meses"'>
            6M
        </button>
        <button type="button" class="btn btn-secondary dimensions btn-grafica" id="graphAll" (click)='filterHistorical(); labelGraph = "Histórico"'>
            Histórico
        </button>
    </div>
    <canvas id="chartPoint"></canvas>
</div>