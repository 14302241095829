<div class="d-flex justify-content-between" style="padding-bottom: 15px;">
    <select class="custom-select" style="width: 100px;" *ngIf="this.dataTable.showData !== undefined" [(ngModel)]="show"
        (change)="initTable()">
        <option *ngFor="let data of dataTable.showData" value="{{ data }}">{{ data }}</option>
    </select>
    <div class="input-group" style="width: 200px;" *ngIf="dataTable.showSearch">
        <input type="text" class="form-control" [(ngModel)]="search" (input)="initTable()">
        <div class="input-group-append">
            <span class="input-group-text"><i class="fa fa-search" aria-hidden="true"></i></span>
        </div>
    </div>
</div>
<div class="d-none d-md-flex table-responsive-xl">
    <table class="table table-striped table-dark my-table text-center">
        <thead class="thead-style">
            <tr>
                <ng-container *ngFor="let data of dataTable.headerRow">
                    <th scope="col" *ngIf="data.show">{{ data.text }}</th>
                </ng-container>
                <th scope="col" *ngIf="verifyButtonsShow()">Acciones</th>
            </tr>
        </thead>
        <tbody *ngIf="arrayData.length >= 1; else noData">
            <tr *ngFor="let data of arrayData[indexTable - 1]">
                <ng-container *ngFor="let dataTable of dataTable.headerRow; let i = index" >
                    <td *ngIf="dataTable.show" [ngClass]="getColumnBgColor(dataTable,data)">
                        {{ data[i] }}
                    </td>
                </ng-container>
                <td *ngIf="verifyButtonsShow()">
                    <div class="d-flex justify-content-md-around">
                        <button class="btn btn-info btn-sm editar" *ngIf="dataTable.buttons.edit.show"
                            (click)="onEdit(data)"><i class="fas fa-pencil-alt"></i> {{ dataTable.buttons.edit.text }}</button>
                        <button class="btn btn-danger btn-sm eliminar" *ngIf="dataTable.buttons.delete.show"
                            (click)="onDelete(data)"><i class="fas fa-window-close"></i> {{ dataTable.buttons.delete.text }}</button>
                        <button class="btn btn-info btn-sm detalles" *ngIf="dataTable.buttons.detail.show"
                            (click)="onDetail(data)"><i class="fas fa-info-circle"></i> {{ dataTable.buttons.detail.text }}</button>
                    </div>
                </td>
            </tr>
        </tbody>
        <ng-template #noData>
            <tbody>
                <tr style="text-align: center;">
                    <td
                        [attr.colspan]="(verifyButtonsShow()) ?  dataTable.headerRow.length + 1: dataTable.headerRow.length">
                        <b>{{ dataTable.messageEmpty }}</b>
                    </td>
                </tr>
            </tbody>
        </ng-template>
    </table>
</div>
<div class="d-md-none">
    <div *ngIf="arrayData.length >= 1; else noDataMovile">
        <div class="card p-3 table-dark text-color border-radious" *ngFor="let data of arrayData[indexTable - 1]">
            <div class="card-body">
                <div class="container">
                    <div class="row row-cols-2">
                        <div class="col text-center">
                            <div *ngFor="let dataTable of dataTable.headerRow">
                                <p *ngIf="dataTable.show">
                                    {{ dataTable.text }}
                                </p>
                            </div>
                            <p *ngIf="verifyButtonsShow()">
                                Acciones
                            </p>
                        </div>
    
                        <div class="col text-center">
                            <div *ngFor="let dataTable of dataTable.headerRow; let i = index">
                                <p *ngIf="dataTable.show">
                                    {{ data[i] }}
                                </p>
                            </div>
                            <div *ngIf="verifyButtonsShow()">
                                <div class="d-flex flex-column justify-content-center">
                                    <button class="btn btn-info btn-sm editar my-2" *ngIf="dataTable.buttons.edit.show"
                                    (click)="onEdit(data)"><i class="fas fa-pencil-alt"></i> {{ dataTable.buttons.edit.text }}</button>
                                    <button class="btn btn-danger btn-sm eliminar my-2" *ngIf="dataTable.buttons.delete.show"
                                        (click)="onDelete(data)"><i class="fas fa-window-close"></i> {{ dataTable.buttons.delete.text }}</button>
                                    <button class="btn btn-info btn-sm detalles my-2" *ngIf="dataTable.buttons.detail.show"
                                        (click)="onDetail(data)"><i class="fas fa-info-circle"></i> {{ dataTable.buttons.detail.text }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template #noDataMovile>
        <div class="card p-3 table-dark text-color border-radious">
            <div class="card-body text-center">
                <b>{{ dataTable.messageEmpty }}</b>
            </div>
        </div>
    </ng-template>
</div>
<div class="d-flex justify-content-between">
    <div>
        <ng-container *ngIf="arrayData[indexTable - 1] !== undefined;">
            Mostrando <b>{{ arrayData[indexTable - 1].length || 0 }}</b> registros de <b>{{ dataRows.length || 0 }}</b>
        </ng-container>
    </div>
    <nav aria-label="Navigation">
        <ul class="pagination">
            <li class="page-item">
                <a class="page-link" href="javascript:void(0)"
                    (click)="indexTable === 1 ? indexTable = indexTable : indexTable = indexTable - 1">
                    <span aria-hidden="true" class="text-white">&laquo;</span>
                </a>
            </li>
            <div *ngFor="let data of arrayData; let i = index">
                <li class="page-item" [ngClass]="indexTable === (i + 1) ? 'active' : 'inactive'">
                    <a class="page-link text-white" href="javascript:void(0)" (click)="indexTable = (i + 1)">{{ i + 1 }}</a>
                </li>
            </div>
            <li class="page-item">
                <a class="page-link" href="javascript:void(0)"
                    (click)="indexTable === arrayData.length ? indexTable = indexTable : indexTable = indexTable + 1">
                    <span aria-hidden="true" class="text-white">&raquo;</span>
                </a>
            </li>
        </ul>
    </nav>
</div>