import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// * Service
import { GetDataService } from 'src/app/services/get-data.service';

// * Variable for SheetJS
declare const XLSX: any;

@Component({
  selector: 'app-bascula-detail',
  templateUrl: './bascula-detail.component.html',
  styleUrls: ['./bascula-detail.component.css']
})
export class BasculaDetailComponent implements OnInit {
  // * Formulario de reporte
  reportForm = new FormGroup({
    initDate: new FormControl('', [Validators.required]),
    finishDate: new FormControl('', [Validators.required]),
  });

  uid: string = '';
  bascula: any = []
  tablaTablilla: any = [];

  constructor(private service: GetDataService, private route: ActivatedRoute, private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.uid = this.route.snapshot.paramMap.get("uid") || '';
    this.service.getBasculaByIdData(this.uid).subscribe(dataT => {
      dataT.map(camion => {
        this.bascula = camion.payload.doc.data();
        console.log(this.bascula);
        this.service.getDataBascula('op_iotCore', this.uid).subscribe(dataTablilla => {
          dataTablilla.map(tablilla => {
            console.log("SENSOR", tablilla)
                this.tablaTablilla = { docIdTablilla: tablilla.payload.doc.id, tablilla: tablilla.payload.doc.data(), bascula: this.bascula };
                console.log("TABLA TABLILLA", this.tablaTablilla)
          });
        });
      })
    });
  }

  async generateReport() {
    if (this.reportForm.valid) {
      let tbody = [[]];
      const reportHeader =
        [
          [null],
          [null],
          [null, `Fecha Inicio: ${this.reportForm.value.initDate}`, null, `Fecha Final: ${this.reportForm.value.finishDate}`],
          [null],
          [null],
          ['Registro', 'Pulsos']
        ];

      const respReport = await this.service.generateReportBascula(this.reportForm.value.initDate, this.reportForm.value.finishDate);
      respReport.forEach(dataReport => {
        for (let i = 0; i < dataReport.docs.length; i++) {
          const element = dataReport.docs[i].data() as {
            pulses: string
            creacionRegistro: {
              nanoseconds: number,
              seconds: number
            }
          };
          const date = new Date(element.creacionRegistro.seconds * 1000);

          const data = [
            `${date.getFullYear()}/${((date.getMonth() + 1) < 10) ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)}/${(date.getDate() < 10) ? '0' + date.getDate() : date.getHours()} ${(date.getHours() < 10) ? '0' + date.getHours() : date.getHours()}:${(date.getMinutes() < 10) ? '0' + date.getMinutes() : date.getMinutes()}:${(date.getSeconds() < 10) ? '0' + date.getSeconds() : date.getSeconds()}`,
            element.pulses.toString()
          ];

          tbody.push(data as [])
        }

        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.aoa_to_sheet([[]]);

        XLSX.utils.book_append_sheet(wb, ws, "KinnilCM-ReporteMaquina");
        XLSX.utils.sheet_add_aoa(ws, reportHeader, { origin: "A1" });
        XLSX.utils.sheet_add_aoa(ws, tbody, { origin: "A6" });

        /*if (!ws["!images"]) ws["!images"] = [];
        ws["!images"].push({
          "!link": "http://sheetjs.com/logo.png",
          '!pos': { x: 700, y: 300, w: 64, h: 64 },
          "!datatype": "remote"
        });*/

        if (!ws['!cols']) ws['!cols'] = [];
        ws['!cols'][0] = { width: 20 };
        ws['!cols'][1] = { width: 20 };
        ws['!cols'][2] = { width: 20 };
        ws['!cols'][3] = { width: 20 };
        ws['!cols'][4] = { width: 20 };
        ws['!cols'][5] = { width: 20 };

        XLSX.writeFile(wb, "KinnilCM-ReporteBascula.xlsx", { bookSST: true, cellStyles: true, bookImages: true });
      });
    } else {
      alert('Es necesario ingresar un rango de fechas')
    }
  }

  timestampToDate(fecha: any) {
    let data = new Date(fecha * 1000);
    return this.datePipe.transform(data, 'dd/MM/YY HH:mm:ss');
  }
}
